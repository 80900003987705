.test-device-modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    height: 100vh;
    width: 100vw;
    background-color: rgba(199, 197, 197, 0.5);    
    justify-content: center;
    align-items: center;   
    transition: visibility 0s linear 0.3s, opacity 0.3s linear;
   
  }
  
  .test-device-modal {
    background-color: white;
    padding: 20px;
    border-radius: 5px;
    height: 34rem;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    z-index: 1000;
    transform: translateY(0);
    animation: slideDown 0.3s ease-out; 

  }

  .test-device-modal-overlay.active .device-modal {
    transform: translateY(0);
    animation: slideDown 0.3s ease-out;
  }
  .test-device-modal-content-title{
    font-size: 2rem;
    color: var(--primary-color);
    margin-bottom: 1.5rem;
  }

.test-action-buttons{
    display: flex;
    justify-content: center;
    gap:1rem;
    padding: 0rem 0.5rem;
}
  
.button{

    background: var(--primary-color);
    color: white;
    padding: 0.5rem;
    border: none;
    border-radius: 0.5rem;
   
    margin-top: 2rem;
}

.button:hover{
    background: var(--select-color);
}

.close-btn {    
border-radius: 0.5rem ;
padding: 0.5rem;
border: none;
color: white;
}
.close-btn {     
background-color: var(--disabled-color);
}
.close-btn:hover{
    background-color: var(--select-color);
}

.button:disabled{
    background: var(--disabled-color);
}



.test-modal-input-group{
    display: flex;
    flex-direction: column;
    width: 25%;
    justify-content: center;
}

input{
    border:none;
}

input:focus
{
border: 1px solid var(--select-color);
box-shadow: 0 0 3px var(--select-color);
outline-offset: 0px;
outline: none;
}

.input-item{
    border: 1px solid #59817F;
    border-radius: 0.5rem;
    padding: 0.5rem;
    text-align: center;
    margin-bottom: 1rem;
}


.notification{
    background: var(--success-color);
    border-radius: 0.5rem;
    padding: 0.25rem;
    color: white;
    margin-top: 0rem;
    margin-bottom: -1rem;
    font-size:small;
}


@keyframes slideDown {
    0% {
      transform: translateY(-100%);
    }
    100% {
      transform: translateY(0);
    }
  }

  @keyframes slideUp {
    100% {
      transform: translateY(-100%);
    }
    0% {
      transform: translateY(0);
    }
  }
  
