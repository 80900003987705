/* Rectangle.css */
.rectangle {
    display: grid;
    grid-template-columns: repeat(11, 1fr); /* 11 columns (33 squares in total) */
    gap: 1px; /* Espaço entre os quadrados */
    width: 720px; /* Largura total do retângulo (11 * 24px + 10 * 4px) */
    height: 510px; /* Altura total do retângulo (3 * 24px + 2 * 4px) */
    border: 6px solid #ffff; /* Borda do retângulo */
    padding: 2px; /* Espaçamento interno */
  }
  
  .square {
    width: 100px; /* Largura do quadrado */
    height: 100px; /* Altura do quadrado */
    background-color: #ffff; /* Cor de fundo do quadrado */
    border: 2px solid #E3E9ED; /* Borda do quadrado */
  }
  .square-number {
  
    margin-left: 40px;
    margin-top: 50px;
    font-size: 30px; /* Adjust the font size as needed */
    color:#93aca6; /* Adjust the color of the number as needed */
  }



.modal-content {
  padding: 20px; /* Optional: Add padding to the modal content */
  text-align: center; /* Center the content inside the modal */
}

  .ReactModal__Content .ReactModal__Content--after-open{
    border: 0 !important;
    background: 0 !important;
  }
  /* Add these styles to center the modal */
.custom-modal {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  min-width: 400px; /* Adjust the maximum width of the modal as needed */
  background-color: #fff ; /* Adjust the background color of the modal as needed */
  border: 2px solid var(--primary-color);
  padding: 3rem 2rem;
  border-radius: 1.5rem;
  box-shadow: 0px 25px 25px 5px rgba(6, 67, 56, 0.25);

}



/* Add these styles for customizing the overlay (background) of the modal */
.custom-overlay {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba(0, 0, 0, 0.5); /* Adjust the overlay background color and transparency */
  height: 100vh; /* Adjust the height of the overlay as needed */
}

.square.hovered {
  background-color: orange; /* Ou qualquer outra cor que você desejar */
  cursor: pointer;
}