.circles-container {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 90vh;
    color:#59817F;
    
    
  }

  .circle-division {
    background-color: white;
  }

  /* Estilos para o modal */
.modal-content {
  background-color: #fff;
  padding: 20px;
  border-radius: 5px;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
  max-width: 300px;
  margin-left: 100%;
  margin-top: 100%;
}

.modal-title {
  font-size: 20px;
  margin-bottom: 10px;
}

.modal-body {
  font-size: 16px;
}

.modal-footer {
  margin-top: 20px;
  text-align: right;
}

.modal-close-button {
  cursor: pointer;
  color: #aaa;
  font-size: 18px;
}

/* Estilos para o botão Definir */
.define-button {
  background-color: #59817F;
  color: #fff;
  border: none;
  padding: 8px 16px;
  border-radius: 5px;
  cursor: pointer;
}

.define-button:hover {
  background-color: #59817F;
}
.ReactModal__Content .ReactModal__Content--after-open{
  border: 0 !important;
  background: 0 !important;

}

.fil0 {
  fill: #fff; /* Defina a cor de preenchimento como branco (#fff) */
}
.fil1 {
  fill: #fff; /* Defina a cor de preenchimento como branco (#fff) */
}


.hovered {
 
  fill: #fa853e; /* Change to your desired hover color */
}
.hovered1 {
 
  fill: #fa853e; /* Change to your desired hover color */
}


  
.span-index{
  background: var(--secondary-color);
  color: white;
  border-radius: 0.5rem ;
  padding: 0.1rem 1rem;
  display: flex;
  position: relative;
  top: 0;
  left: 0.5rem;
}

.right-map-container{ 
  display: flex;
  padding: 1rem;
  text-align: center;
  color: #59817F;
  border-radius: 2rem;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
  flex-direction: column;
  justify-content: flex-end;
  box-shadow: 0px 25px 25px 5px rgba(6, 67, 56, 0.25);
  border: 3px solid #59817F;
}

.center-map-container{ 

  text-align: center;
  color: #59817F;
  border-radius: 200rem;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
  flex-direction: column;
  justify-content: flex-end;
  box-shadow: 0px 25px 25px 5px rgba(6, 67, 56, 0.25);
  border: 5px solid #ffffff;
}

.right-container{ 
  display: flex; 
  flex-direction: column;
  justify-content: flex-end;
  border: 1px solid green;
  
}

.button-map-ref{
  width: 3.5rem;
  height: 3.5rem;
  border:none;
  padding: 0rem;
  border-radius: 2.5rem;
}

.button-map-ref.active{
 background: #59817F;
 color: white;
}

.button-map-ref:hover{
  background: #fa853e;
  color: white;
  scale: 1.11;
 }

.right-map-container-wrapper{
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-block-end: 20%;
}

.svg.g{
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
}

.bottom-section {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-end;
  height: 100%;
}

#Layer_x00201 {
  border: 100px solid #7da4a3;
  width: 100%;
  height: 100%;
}

@media (max-width: 768px) {
  .center-map-container {
    width: 100%; /* Adjust the width for mobile screens */
    height: 100%;
 margin: 0% !important;
  }
  .right-map-container{
    margin-top: 2rem;
    margin-block-end: 45%;
  }
  .card-schedule{
    margin-bottom: "3rem"
  }
  
}

.dashboard-container {
  display: grid;
  grid-template-columns: 1fr 2fr; /* Adjust the ratio according to your design needs */
  gap: 20px; /* Space between columns */
  padding: 20px;
  height: 84vh; /* Taking the full height of the view */
  /* border: 1px solid green; */
  width: 100%;
}

.dashboard-column {
  padding: 10px;
  overflow-y: auto; /* Adds scroll to each column if content is too long */
}

.left-card{
  background: white;
  border: 2px solid var(--primary-color);
  border-radius: 1rem;
  padding: 1.5rem 2rem;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
}

.center-map-container {
  width: 100%;
  height: auto;
  max-height: 100%;
  justify-content: right;
  text-align: right;
  align-items: flex-end;
}

@media (max-width: 768px) {
  .dashboard-container {
      grid-template-columns: 1fr; /* Stack columns on smaller screens */
  }
}

.map-overlay-container {
  position: relative;
}

.map-overlay {
  position: absolute;
  background-color: rgba(0, 0, 0, 0); /* Transparent */
  cursor: pointer;
}

.overlay-left {
  /* Coordinates and dimensions for the left section */
  top: 0;
  left: 0;
  width: 33.33%;
  height: 100%;
}

.overlay-center {
  /* Coordinates and dimensions for the center section */
  top: 0;
  left: 33.33%;
  width: 33.34%;
  height: 100%;
}

.overlay-right {
  /* Coordinates and dimensions for the right section */
  top: 0;
  right: 0;
  width: 33.33%;
  height: 100%;
}

.modal-close-btn{
  background-color: var(--primary-color);
  padding: 0.2rem;
  border-radius: 50%;
  position: relative;
  right: 0.5rem;
}

.modal-close-btn:hover{
  background: var(--select-color);
}

.group-definition{
  display: flex;
  flex-direction: column;
}

.water-clock{
  position: relative;
  top: 1rem;
  right: 2rem;
  background: white;
  border-radius: 50%;
  width: 2rem;
  height: 2rem;
  justify-content: center;
  align-items: center;
  display: inline-block;
}