:root{
    --primary-color: #59817f;
    --secondary-color: #90cba0;
    --text-color: #092d4b;
    --background-color: #F5F5EB;
    --select-color: #ff9c31;
    --border-color: #dadee2;
    --disabled-color: #bdbdbd;
    --enabled-color: #00A0E3;
    --focus-color: #dadee2;
    --error-color: #dc3545;
    --success-color: #28a745;
    --alternative-color: #471b7a;
}

.main-container {
    position: relative;
    text-align: center; /* Center the title */   
    height: calc(100vh - 6.5rem);
    top: 3.5rem;
    z-index: 0;
  }

  .breadcrumb-container{
    position: absolute;
    display: flex;
    width: 100%;
    background: var(--background-color);
    top: 0;
  }

  .input-group{
    display: flex;
    flex-direction: column;
    width: 100% !important;
}

input{
    border:none;
}

input:focus
{
border: 3px solid orange;
box-shadow: 0 0 3px rgb(233, 100, 17);
outline-offset: 0px;
outline: none;
}

.input-item input{
    display: flex;
    flex-direction: row;
    border: 3px solid #59817F;
    border-radius: 0.5rem;
    padding: 0.5rem;
    text-align: center;
    width: 100%;
}
