.login-page{
    display: flex;
    height: 100vh;
    flex-direction: column;
    background: #FEFEFB;
}

 .login-card-wrapper{
    width: 290px;
    text-align: center;
    
    border-radius: 1rem;
    /* box-shadow: 0px 25px 25px 5px rgba(0, 0, 0, 0.25); */
} 

.login-form-wrapper{
    padding-top: 0.5rem;
    padding-bottom: 1rem;
}

.login-form-item-wrapper{
    padding-top: 0.5rem;
    padding-left: 0.5rem;
    padding-right: 0.5rem;
    
}

.input-item-wrapper:focus {
    border-color: #59817f !important;
    box-shadow: none !important;
  }


  .footer-developer{
    font-size: x-small;
    color: #59817f;
    margin-top: 5rem;
    font-family: system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
  }
  
  .error-wrapper{
   
    padding: 0.2rem;
   margin-left: 0.5rem;
   margin-right: 0.5rem;
    font-size: x-small;
    background: rgb(233, 100, 17);
    border-radius: 2rem;
    color: white;
  }

  .login-button-btn{
    background: var(--primary-color);
    border-radius: 2rem;
    border: none;
    padding: 0.5rem;
    color: white;
  }

  .login-button-btn:hover{
    background: var(--select-color);
  }

  