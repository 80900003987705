/* Apply these styles to the body element to fill the entire viewport */
.settings-container {
    margin: 0;
    padding: 0;
    display: flex;
    min-height: 70vh;
    
  }
  
  /* Apply these styles to the .settings-container */
  .settings-wrapper {
    flex: 1;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
   
  }

  /* .clock{
    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' xml:space='preserve' width='30mm' height='30mm' style='shape-rendering:geometricPrecision;text-rendering:geometricPrecision;image-rendering:optimizeQuality;fill-rule:evenodd;clip-rule:evenodd' viewBox='0 0 5000 5000'%3E%3Cpath d='M2494.59 3992.12c820.99 0 1485.22-664.97 1485.22-1485.96 0-821.03-664.23-1486-1485.22-1486s-1486.7 664.97-1486.7 1486c0 820.99 665.71 1485.96 1486.7 1485.96zm-.74-2723.64c683.85 0 1237.68 553.83 1237.68 1237.68 0 683.81-553.83 1237.63-1237.68 1237.63-683.81 0-1237.67-553.82-1237.67-1237.63 0-683.85 553.86-1237.68 1237.67-1237.68zm-6.15 119.39h.04c64.84 0 117.8 53 117.8 117.83v1026.22c0 64.84-52.96 117.84-117.8 117.84h-.04c-64.87 0-117.83-53-117.83-117.84V1505.7c0-64.83 52.96-117.83 117.83-117.83zM1927.22 2828.1c-30.25-57.33-8.08-128.98 49.25-159.23l453.86-239.31c57.33-30.25 128.98-8.09 159.23 49.25 30.25 57.37 8.05 129.01-49.25 159.23l-453.86 239.35c-57.33 30.17-129.01 8.04-159.23-49.29z' style='fill:%2359817f;fill-rule:nonzero'/%3E%3C/svg%3E");
    background-repeat: no-repeat;
    background-position: center;
    display: flex;
    text-align: center;
   
    
} */

.card-schedule{
    display: flex;
    justify-content: center;
    border: 3px solid #59817F;
    padding: 5rem 1rem;
    border-radius: 1.5rem;
    box-shadow: 0px 25px 25px 5px rgba(6, 67, 56, 0.25);

  
}
  
.button{

    background: #59817F;
    color: white;
    padding: 0.5rem;
    border: none;
    border-radius: 0.5rem;
   
    margin-top: 2rem;
}

.button:hover{
    background: rgb(233, 100, 17);
}

.input-group{
    display: flex;
    flex-direction: column;
    width: 100% !important;
}

input{
    border:none;
}

input:focus
{
border: 3px solid orange;
box-shadow: 0 0 3px rgb(233, 100, 17);
outline-offset: 0px;
outline: none;
}

.input-item input{
    display: flex;
    flex-direction: row;
    border: 3px solid #59817F;
    border-radius: 0.5rem;
    padding: 0.5rem;
    text-align: center;
    width: 100%;
}


.notification{
    background: rgb(233, 100, 17);
    border-radius: 0.5rem;
    padding: 0.2rem;
    color: white;
    margin-top: 1rem;
    margin-bottom: -2rem;
    font-size:small;
}

