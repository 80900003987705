/* TabStyles.css */

/* Style for active tab */
.nav-link.active {
    background-color: #59817f !important;
    color: white !important;
}

/* Style for inactive tabs */
.nav-link:not(.active) {
    background-color: #ffffff ;
    color: #59817f ;
    
}

/* Optional: Increase the font size of the tab items */
.nav-link {
    font-size: 12px;
    border: 2px solid #59817f;
  }
  
  /* Style for hover effect on all tabs */
  .nav-link:hover {
    background: rgb(233, 100, 17);
    color: white;
  }
  