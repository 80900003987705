.content-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  
  
  .content-actions {
    display: flex;
    gap: 10px;
    position: relative;
    right: 0;
    width: 100%;
    justify-content: flex-end;
    padding: 0.5rem;
  }
  
  .device-action-button {
    background-color: var(--primary-color);
    border: none;
    border-radius: 0.5rem;
    font-size: small;
    padding: 0.5rem;
    color: white
   
  }
  
  .device-action-button:hover {
    background-color: var(--select-color); /* Darker background color on hover */
  }
  
.devices-container {
    display: flex;
    justify-content: space-around; /* Distribute cards with equal space around */
    flex-wrap: wrap; /* Wrap onto next row if not enough space */
    gap: 20px; /* Space between cards */
    align-items: baseline;
    height: 60vh;
    width: 100%;

  }

.status-indicator {
    height: 10px;
    width: 10px;
    border-radius: 50%;
    display: inline-block;
  }
  
  .status-indicator.active {
    background-color: var(--success-color);
    border: none;
  }
  
  .status-indicator.inactive {
    border: 1px solid var(--disabled-color);
   
  }
  
  .icon-button {
    background: none;
    border: none;
    cursor: pointer;
  }
  
  .action-button {
    background: var(--disabled-color);
    display: inline-block;
    width: 1.5rem;
    height: 1.5rem;
    border-radius: 50%;
    justify-content: center;
    align-items: center;
    text-align: center;
    padding: 0.09rem;
  }

  .action-button.active{
    background: var(--enabled-color);
  }

  .action-button.inactive:hover{
    background: var(--success-color);
  }

  .action-button.active:hover{
    background: var(--error-color);
  }
  
  .table-scroll-container {
    display: flex;
    flex-direction: column;
    height: 63vh;
    overflow-y: auto;
    width: 100%;
    background: white;
    padding: 0rem 0.5rem;
    border-radius: 0.5rem;
  }

  .table thead th{
    position: sticky; /* Makes the header stick to the top */
    top: 0.5rem; /* Zero distance from the top of the container */
    z-index: 0; /* Ensure the header is above other content when scrolling */
    background-color: var(--primary-color); /* Set a background color */
    color: white; /* Set the text color */
  }
  
  
  .devices-table tbody tr {
    height: 30px !important;
    min-height: 30px !important;
    max-height: 30px !important;

}

.devices-table tbody {
  padding-top: 1rem 
}
.devices-background-table{
    height: 1rem;
    display: inline-block;
    position: sticky;
    background: white;
    padding: 0.26rem;
    top: 0;
}

  /* Optional: Styling the scrollbar */
  .table-scroll-container::-webkit-scrollbar {
    display: none;
  }
  
  .table-scroll-container::-webkit-scrollbar-thumb {
    display: none;
  }
  
  .table-scroll-container::-webkit-scrollbar-thumb:hover {
    display: none;
  } 
  
  .devices-table th {
    color: white;   
  }

  .devices-table th:first-child{
    border-radius: 0.5rem 0rem 0rem 0.5rem;
  }

  .devices-table th:last-child{
    border-radius: 0rem 0.5rem 0.5rem  0rem 
  }
  
  .devices-table td {
    vertical-align: middle;
    padding: 0.25rem;
  }

  .devices-table tr:hover{
    background-color: var(--background-color);
  }

  .devices-table td:nth-child(3){
    display:flex;
    justify-content: center;
    width: 100%;
    align-items: center;
    text-align: center;
    vertical-align: middle;
   gap: 0.25rem;
  }
  
  
  
  .devices-table td svg {
    cursor: pointer;
   
  }

  .table-fixed-header th,
.table-fixed-header td {
  padding: 8px; /* Adjust padding as needed */
  text-align: left; /* Text align for most cells */
  border-radius: 0.5rem;
}

/* Align the text for the last three header cells to the right */
.table-fixed-header thead th:last-child,
.table-fixed-header thead th:nth-last-child(2),
.table-fixed-header thead th:nth-last-child(3) {
  text-align: right;
  width: 10%;
}

/* Align the content for the last three cells of each row to the right */
.table-fixed-header tbody td:last-child,
.table-fixed-header tbody td:nth-last-child(2),
.table-fixed-header tbody td:nth-last-child(3) {
  text-align: right;
  width: 10%;
}

/* Style for the button container to keep buttons together */
.button-container {
  display: flex;
  justify-content: flex-end;
  gap: 10px; /* Adjust the space between buttons as needed */
}

/* Button styles */
.icon-button {
  padding: 5px 10px; /* Adjust padding for buttons */
  margin: 0; /* Remove margin to prevent extra spacing */
  /* Add other button styling (background, border, etc.) */
}

.icon-button.edit:hover{
    color: var(--enabled-color);
}
.icon-button.delete:hover{
    color: var(--error-color);
}

.horta-value-span{
  background: var(--select-color);
  color: white;
  width: 1.5rem;
  height: 1.5rem;
  justify-content: center;
  align-items: center;
  text-align: center;
  display: flex;
  border-radius: 50%
}

.horta-value-span.inactive {
  background: var(--focus-color);
 
}